export class SeoDescription {
    title: string;
    description: string;
    keywords: string;
    imageURL: string;
    constructor(data: { title?: string, description?: string, imageURL?: string, keywords?: string }) {
        this.title = data.title ?? "Главная";
        this.description = data.description ?? "Продажа и ремонт рукавов, шлангов высокого давления (РВД) в Нижнем Новгороде и Дзержинске. Купите качественные РВД для гидравлических систем. Ремонт и изготовление РВД по вашим требованиям. Широкий выбор, низкие цены.";
        this.keywords = data.keywords ?? "РВД-Сервис, рвд52, rvd52, Рукава высокого давления, фитинги, шланги высокого давления";
        this.imageURL = data.imageURL ?? "assets/images/seo/rvd.png";
    }
}

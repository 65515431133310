import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { SeoDescription } from '../data/seo/seo.class';
export {SeoDescription} from '../data/seo/seo.class';

@Injectable({
	providedIn: 'root'
})

export class SeoService {


	updateSeo(seo:SeoDescription) {
		if(seo.title) {
			this.updateTitle(seo.title);
		}
		if(seo.description) {
			this.updateDescription(seo.description);
		}
		if(seo.keywords) {
			this.updateKeywords(seo.keywords);
		}
		if(seo.imageURL) {
			this.updateImage(seo.imageURL);
		}
	}
	constructor(private meta: Meta, private title: Title, @Inject(DOCUMENT) private _document: Document) {


		this.meta.addTags([
			{
				name: 'keywords',
				content: 'РВД-Сервис, рвд52, rvd52, Рукава высокого давления, фитинги, шланги высокого давления',
			},
			{ property: 'og:site_name', content: 'РВД-Сервис' },
			{ property: 'og:title', content: 'Главная' },
			{ property: 'og:image', content: 'assets/images/seo/rvd.png' },
			{ property: "og:type", content: "website" },
			{ property: 'og:description', content: 'Продажа и ремонт рукавов, шлангов высокого давления (РВД) в Нижнем Новгороде и Дзержинске. Купите качественные РВД для гидравлических систем. Ремонт и изготовление РВД по вашим требованиям. Широкий выбор, низкие цены.' },
			{ property: "og:locale", content: "ru_RU" },
			{ name: 'description', content: 'Продажа и ремонт рукавов, шлангов высокого давления (РВД) в Нижнем Новгороде и Дзержинске. Купите качественные РВД для гидравлических систем. Ремонт и изготовление РВД по вашим требованиям. Широкий выбор, низкие цены.' },
			{ itemprop: 'description', content: 'Продажа и ремонт рукавов, шлангов высокого давления (РВД) в Нижнем Новгороде и Дзержинске. Купите качественные РВД для гидравлических систем. Ремонт и изготовление РВД по вашим требованиям. Широкий выбор, низкие цены.' },

			{ name: 'robots', content: 'index, follow' },
			{ name: 'author', content: 'RVD-Service' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
			{ charset: 'UTF-8' },
		]);
	}
	public updateSchema(renderer2: Renderer2, data: string): void {
		let script = renderer2.selectRootElement('.jsonLD', false)
		const text = renderer2.createText(data);
		renderer2.appendChild(script, text);
	}

	updateTitle(title: string) {
		this.title.setTitle(title);
		this.meta.updateTag({ property: 'og:title', content: title }, "property='og:title'");
	}
	updateDescription(description: string) {
		description = description
			.replaceAll('\n', ' ')
			.replaceAll('  ', '')
			.replaceAll('<h2>', '')
			.replaceAll('</h2>', '')
			.replaceAll('<p>', '')
			.replaceAll('</p>', '')
			.replaceAll('<b>', '')
			.replaceAll('</b>', '')
		this.meta.updateTag({ property: 'og:description', content: description }, "property='og:description'");
		this.meta.updateTag({ name: 'description', content: description }, "name='description'");
		this.meta.updateTag({ itemprop: 'description', content: description }, "itemprop='description'");
	}
	updateKeywords(keywords: string) {
		this.meta.updateTag({ name: 'keywords', content: keywords }, "name='keywords'");
	}
	updateImage(imageUrl: string) {
		this.meta.updateTag({ property: 'og:image', content: "https://rvd52.ru/" + imageUrl }, "property='og:image'");
	}
}
